import axios from 'axios';
import store from '../store/store';
import router from '../router/router';
import i18n from '../i18n';
import errorMessage from '@/utils/messages/errorMessage';
import constants from '../utils/constants';

const http = axios.create({
    baseURL: constants.ENOTARIUS_API_URL,
    timeout: 100000,
});

// request
http.interceptors.request.use(
    async config => {
        let localStore = window.localStorage.getItem('localStore');
        if (localStore) {
            let localStoreObject = JSON.parse(localStore);
            if (localStoreObject.auth.token)
                config.headers['Authorization'] = 'Bearer ' +
                    localStoreObject.auth.token;
            config.headers['Accept-Language'] = localStoreObject.locale.globalLocale;
        }
        if (!config.headers['Accept'])
            config.headers['Accept'] = 'application/json';
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

// response
http.interceptors.response.use(
    async response => {
        let status = response.status;
        if (status === 200)
            await store.dispatch('auth/updateToken', response.headers.token);
        return response;
    },
    async error => {
        let errorStatus = error.response.status;
        if (errorStatus === 400) {
            errorMessage(error.response.data);
        } else if (errorStatus === 403) {
            if (error.response.data.message === 'Access Denied') {
                errorMessage(i18n.t('token_expired'), null, 3000);
                await store.dispatch('auth/updateToken', null);
                await store.dispatch('user/updateUser', null);
                await store.dispatch('redirectUrl/updateRedirectUrl', window.location.pathname)
                if (isKiosk())
                    router.push('/kiosk/').then();
            } else
                router.push(isKiosk() ? '/kiosk/403' : '/403').then();
        } else if (errorStatus === 500) {
            errorMessage(i18n.t('internal_server_error'));
        } else if (errorStatus === 418) {
            let message;
            if (error.response.data.code === 'uuid_not_found')
                message = i18n.t('access_error_wrong_uuid', {uuid: error.response.data.object})
            else if (error.response.data.code === 'pin_not_found_in_uuid')
                message = i18n.t('access_error_member_access_denied', {
                    usrname: error.response.data.object.username,
                    uuid: error.response.data.object.uuid
                })
            if (message) {
                errorMessage(message, null, 30000);
                router.push('/my_notarial_act').then();
            }
        }
        if (error.response.config.method === 'post' && errorStatus === 404)
            errorMessage(error.response.data);
        return Promise.reject(error);
    },
);

async function getMethod(self, url, config = null) {
    self.$root.$emit('showLoader');
    return http.get(url, config).then((response) => {
        let status = response.status;
        if (status === 200)
            return response;
    }).catch((error) => {
        console.log(error);
        let errorStatus = error.response.status;
        if (errorStatus === 404) {
            errorMessage(error.response.data);
            router.push(isKiosk() ? '/kiosk/404' : '/404').then();
            self.$root.$emit('hideLoader');
        }
    }).finally(() => {
        self.$root.$emit('hideLoader');
    });
}

async function postMethod(self, url, data = null, config = null) {
    self.$root.$emit('showLoader');
    return http.post(url, data, config).then((response) => {
        return response;
    }).finally(() => {
        self.$root.$emit('hideLoader');
    });
}

async function downloadFileMethod(self, url, params = null) {
    self.$root.$emit('showLoader');
    return http.get(url, {
        params: params,
        responseType: 'blob',
    }).then(response => {
        let contentDisposition = response.headers['content-disposition'];
        let filename = decodeURIComponent(
            contentDisposition.substring(contentDisposition.indexOf('"') + 1,
                contentDisposition.lastIndexOf('"')));

        const link = document.createElement('a');

        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }).catch(error => {
        let errorStatus = error.response.status;
        if (errorStatus === 404) {
            errorMessage(error.response.data);
            router.push(isKiosk() ? '/kiosk/404' : '/404').then();
            self.$root.$emit('hideLoader');
        } else if (errorStatus === 418) {
            errorMessage(i18n.t('access_error_download_file'), null, 30000);
            router.push('/my_notarial_act').then();
        }
    }).finally(() => {
        self.$root.$emit('hideLoader');
    });
}

const isKiosk = () => {
    let localStore = window.localStorage.getItem('localStore');
    let localStoreObject = JSON.parse(localStore);
    return localStoreObject.mode === 'kiosk';
};

export default {
    getMethod: getMethod,
    postMethod: postMethod,
    downloadFileMethod: downloadFileMethod,
};